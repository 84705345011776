// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCiglINpboOI7H5nDc9QYstspTvosRIYjo",
  authDomain: "cartrade-19104.firebaseapp.com",
  projectId: "cartrade-19104",
  storageBucket: "cartrade-19104.appspot.com",
  messagingSenderId: "357121880784",
  appId: "1:357121880784:web:ee482301792ef4f39a24d5",
  measurementId: "G-JPX0KP69SK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
