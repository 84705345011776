// src/views/About.js
import React, { useEffect, useState } from "react";
import { getCars } from "../firebase/cars"; // Asegúrate de tener esta importación si no está ya
import CarListSold from "../components/carListSold";
import fotoMatias from "../utils/fotoMatias.jpg";
import foto_antonio from "../utils/foto_antonio.jpg";
import { BeatLoader } from "react-spinners";

function About() {
  const [soldCars, setSoldCars] = useState([]);
  const [loading, setLoading] = useState(true); // Nuevo estado para manejar la carga

  useEffect(() => {
    const fetchSoldCars = async () => {
      setLoading(true); // Comenzar a cargar
      try {
        const carsData = await getCars();
        const soldCarsData = carsData.filter((car) => car.estado === "Vendido");
        setSoldCars(soldCarsData);
      } catch (error) {
        console.error("Error fetching sold cars:", error);
      } finally {
        setLoading(false); // Finalizar la carga, independientemente del resultado
      }
    };

    fetchSoldCars();
  }, []);

  // Esta es solo una estructura de ejemplo. Puedes ajustarla según tus necesidades.
  const owners = [
    {
      name: "Matias Gaedechens",
      imageUrl: fotoMatias,
      celular: "+56 9 7664 9440",
      mail: "",
    },
    {
      name: "Antonio Silva",
      imageUrl: foto_antonio, // Ruta a la imagen del dueño 2
      celular: "+56 9 7135 6516",
      mail: "",
    },
  ];

  return (
    <div className="about-page p-6 space-y-8 lg:mx-20">
      <h1 className="text-3xl font-bold mb-8 text-left">Nosotros</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center justify-items-center">
        {owners.map((owner, index) => (
          <div key={index} className="text-center">
            <div className="avatar m-auto">
              <div className="w-60 h-60 rounded-full border overflow-hidden mx-auto">
                <img
                  src={owner.imageUrl}
                  alt={owner.name}
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
            <h2 className="text-xl font-semibold mt-4">{owner.name}</h2>
            <p>{owner.celular}</p>
          </div>
        ))}
      </div>

      {/* Descripción del auto */}
      <div className="my-8 rounded-lg overflow-hidden border bg-white p-4 shadow-xl">
        <p className="text-gray-700 text-center">
          En Car Trade, somos un equipo de profesionales expertos en compra y
          venta de autos, dedicados a simplificar tu experiencia. Ya sea
          vendiendo tu auto actual o encontrando tu vehículo ideal, nos
          encargamos de todo por ti. Comprendemos los desafíos de las
          transacciones automotrices y ofrecemos soluciones personalizadas. Al
          vender, aseguramos el mejor trato; al comprar, seleccionamos las
          opciones más adecuadas a tus necesidades. Nuestra eficiencia y
          confiabilidad hacen el proceso transparente y sencillo. ¡No hay nadie
          como nosotros, CarTrade te simplifica la vida!
        </p>
      </div>

      <h2 className="text-3xl font-bold text-left">Autos Vendidos</h2>
      <div className="landing-page container mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        {loading ? (
          <div className="flex flex-col items-center justify-center">
            <BeatLoader size={30} color="#023e8a" />
            <p className="mt-4">Cargando autos vendidos...</p>
          </div>
        ) : (
          <div className="flex justify-center">
            <CarListSold cars={soldCars} />
          </div>
        )}
      </div>
    </div>
  );
}

export default About;
