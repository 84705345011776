// src/components/CarCard.js
import React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../utils/Functions";

function CarCard({ car }) {
  return (
    <div className="card w-full h-auto bg-base-100 shadow-xl relative">
      {/* Contenedor del badge */}
      <div className="badge badge-success font-bold absolute top-0 left-0 m-4 rounded-lg">
        {car.estado}
      </div>

      <figure className="w-full h-60 rounded-t-2xl overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={car.fotos}
          alt={car.modelo}
        />
      </figure>
      <div className="card-body p-4 md:p-6 justify-between">
        <h2 className="card-title text-left">
          {car.marca} {car.modelo} {car.version}
        </h2>

        {/* Contenedor principal para los badges */}
        <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-4 mx-auto w-full text-gray-500">
          {/* Cada contenedor de badge con un icono y texto */}
          {[
            {
              icon: "fa-tachometer-alt",
              text: `${formatNumber(car.kilometros)} Km`,
            },
            { icon: "fa-calendar-alt", text: car.año },
            { icon: "fa-cogs", text: car.transmision },
            { icon: "fa-gas-pump", text: car.combustible },
          ].map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-start space-x-2"
            >
              <div className="icon">
                <i className={`fas ${item.icon}`}></i>
              </div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center">
          {/* Contenedor especial para el precio */}
          <div className="text-2xl font-bold">${formatNumber(car.precio)}</div>
          <div className="card-actions">
            <Link
              to={`/car/${car.id}`}
              className="btn btn-outline bg-principal text-white"
            >
              Ver más
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarCard;
