// src/views/Contact.js
import React, { useState } from "react";
import { FaMailBulk, FaPhone } from "react-icons/fa";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contacto() {
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    axios
      .post("https://cartrade-65f4c7ae0a47.herokuapp.com/enviarMail", data)
      .then((response) => {
        toast.success("Email enviado con éxito");
        e.target.reset(); // Resetea el formulario
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error al enviar el formulario");
        console.error("Error al enviar el formulario:", error);
        setLoading(false);
      });
  };

  return (
    <div className="contact-page p-6 lg:mx-40">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 text-left flex flex-col space-y-4">
          <h1 className="text-3xl md:text-5xl font-bold mb-6 mt-4">
            Contáctanos
          </h1>
          <span className="flex items-center text-lg md:text-xl">
            <FaMailBulk className="text-3xl md:text-5xl my-1 mr-4 text-principal" />{" "}
            Email: contacto@cartrade.cl
          </span>
          <span className="flex items-center text-lg md:text-xl">
            <FaPhone className="text-3xl md:text-5xl my-1 mr-4 text-principal" />{" "}
            Teléfono: +56 9 7664 9440
          </span>
          <span className="flex items-center text-lg md:text-xl">
            <FaPhone className="text-3xl md:text-5xl my-1 mr-4 text-principal" />{" "}
            Teléfono: +56 9 7135 6516
          </span>
        </div>

        <div className="md:w-1/2">
          <form onSubmit={sendEmail} className="space-y-4 my-5">
            <input
              type="hidden"
              name="tipoFormulario"
              value="formularioContacto"
            />
            <div className="flex-1">
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre completo
              </label>
              <input
                type="text"
                id="fullName"
                name="from_name"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Número de teléfono
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="9 1234 5678"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Mensaje
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {loading ? (
                  <BeatLoader size={8} color="#FFFFFF" />
                ) : (
                  "Contactanos"
                )}
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default Contacto;
