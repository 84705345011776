import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import LandingPage from "./views/LandingPage";
import CarDetails from "./views/CarDetails"; // Asumiendo que creas este nuevo componente
import Footer from "./components/Footer";
import Contacto from "./views/Contacto";
import About from "./views/About";
import Sell from "./views/Sell";
import Buy from "./views/Buy";

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/car/:id" element={<CarDetails />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/about" element={<About />} />
            <Route path="/sell" element={<Sell />} />
            <Route path="/buy" element={<Buy />} />
          </Routes>
        </div>
        <div className="mt-10">
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
