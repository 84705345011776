import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logofondo from "../utils/logofondo.svg";
import WhatsAppButton from "./Whatsapp";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar el menú desplegable
  const location = useLocation(); // Obtener la ruta actual

  // Función para cambiar el estado de menuOpen
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Función para cerrar el menú
  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Función para determinar si la ruta está activa
  const isActive = (path) => location.pathname === path;

  return (
    <div className="navbar bg-base-200 flex items-center">
      <div className="container mx-auto p-0 m-0">
        <div className="navbar-start flex items-center text-left">
          <div className="dropdown">
            <label
              tabIndex={0}
              className="btn btn-ghost lg:hidden"
              onClick={toggleMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className={`menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 gap-x-2 ${
                menuOpen ? "block" : "hidden"
              }`}
            >
              <li className={location.pathname === "/" ? "bg-blue-100" : ""}>
                <Link to="/" onClick={closeMenu}>
                  Inicio
                </Link>
              </li>
              <li
                className={location.pathname === "/about" ? "bg-blue-100" : ""}
              >
                <Link to="/about" onClick={closeMenu}>
                  Nosotros
                </Link>
              </li>
              <li
                className={location.pathname === "/sell" ? "bg-blue-100" : ""}
              >
                <Link to="/sell" onClick={closeMenu}>
                  Vender
                </Link>
              </li>
              <li className={location.pathname === "/buy" ? "bg-blue-100" : ""}>
                <Link to="/buy" onClick={closeMenu}>
                  Comprar
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/contacto" ? "bg-blue-100" : ""
                }
              >
                <Link to="/contacto" onClick={closeMenu}>
                  Contacto
                </Link>
              </li>
            </ul>
          </div>
          <Link to="/" className="normal-case">
            <img src={logofondo} alt="Logo" className="h-14" />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1 gap-x-2">
            <li className={isActive("/") ? "bg-blue-100 rounded-md" : ""}>
              <Link to="/" className="text-lg">
                Inicio
              </Link>
            </li>
            <li className={isActive("/about") ? "bg-blue-100 rounded-md" : ""}>
              <Link to="/about" className="text-lg">
                Nosotros
              </Link>
            </li>
            <li className={isActive("/sell") ? "bg-blue-100 rounded-md" : ""}>
              <Link to="/sell" className="text-lg">
                Vender
              </Link>
            </li>
            <li className={isActive("/buy") ? "bg-blue-100 rounded-md" : ""}>
              <Link to="/buy" className="text-lg">
                Comprar
              </Link>
            </li>
            <li
              className={isActive("/contacto") ? "bg-blue-100 rounded-md" : ""}
            >
              <Link to="/contacto" className="text-lg">
                Contacto
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar-end flex justify-end pr-4 xl:pr-20">
          <WhatsAppButton />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
