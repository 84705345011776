import React from "react";
import { Link } from "react-router-dom"; // Si estás usando React Router
import fotoInicio2 from "../utils/fotoInicio2.svg";

const WelcomeSection = () => {
  return (
    <div className="flex flex-wrap mt-2 mb-20">
      {/* Columna del Eslogan */}
      <div className="w-full md:w-1/2 flex items-center justify-center">
        {" "}
        {/* Ajusta el color de fondo según tu preferencia */}
        <div className="text-left p-12">
          <h1 className="text-6xl font-bold mb-6">
            Tu próximo auto te espera, descúbrelo con nosotros.
          </h1>
          {/* <h2 className="text-xl mb-16"></h2> */}
          <Link to="/buy" className="btn bg-principal text-white w-1/2">
            COMPRAR
          </Link>
        </div>
      </div>

      {/* Columna de la Imagen */}
      <div className="w-full md:w-1/2 flex justify-center items-center">
        <img
          src={fotoInicio2} // Usando la imagen importada
          alt="Imagen de Bienvenida"
          className="w-4/5 w-full object-cover" // Ajusta la imagen para que cubra completamente el espacio disponible
        />
      </div>
    </div>
  );
};

export default WelcomeSection;
