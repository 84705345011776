import React, { useState, useEffect } from "react";
import CarCardSold from "./CarCardSold";
import { getCars } from "../firebase/cars"; // Asegúrate de que la ruta sea correcta

function CarList({ cars: propsCars }) {
  const [localCars, setLocalCars] = useState([]);

  useEffect(() => {
    if (propsCars) {
      // Si recibimos autos como props, los usamos
      setLocalCars(propsCars);
    } else {
      // De lo contrario, obtenemos los autos de la base de datos
      const fetchCars = async () => {
        try {
          const carsData = await getCars();
          setLocalCars(carsData);
        } catch (error) {
          console.error("Error al obtener autos:", error);
        }
      };
      fetchCars();
    }
  }, [propsCars]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 my-8 w-full max-w-screen-xl">
      {localCars.map((car) => (
        <CarCardSold key={car.id} car={car} />
      ))}
    </div>
  );
}

export default CarList;
