import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CarList from '../components/carList';
import { getCars } from '../firebase/cars';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatNumber } from '../utils/Functions';
import { BeatLoader } from 'react-spinners';

function Buy() {
  const [filteredCars, setFilteredCars] = useState([]); // <-- Agregar este estado para mantener los coches filtrados
  const [loading, setLoading] = useState(true); // Nuevo estado para manejar la carga

  // Aquí puedes agregar el estado y lógica para el filtrado si lo deseas
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [transmissions, setTransmissions] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [cars, setCars] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 50000000]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(50000000);
  const [yearRange, setYearRange] = useState([0, new Date().getFullYear()]);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedTransmission, setSelectedTransmission] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Comenzar a cargar
      try {
        const carsData = await getCars();
        const inStockCars = carsData.filter((car) => car.estado === 'En stock'); // Filtra los autos por estado
        setCars(inStockCars); // Setea el estado con autos en stock
        setFilteredCars(inStockCars); // Setea el estado de autos filtrados con autos en stock
        setBrands([...new Set(inStockCars.map((car) => car.marca))]);
        setModels([...new Set(inStockCars.map((car) => car.modelo))]);
        setYears([...new Set(inStockCars.map((car) => car.año))]);
        setTransmissions([
          ...new Set(inStockCars.map((car) => car.transmision)),
        ]);
        setFuels([...new Set(inStockCars.map((car) => car.combustible))]);
        // Encuentra los precios mínimos y máximos
        const prices = inStockCars.map((car) => car.precio);
        setMinPrice(Math.min(...prices));
        setMaxPrice(Math.max(...prices));
        // Encuentra los años min y max
        const years = inStockCars.map((car) => car.año);
        const minYear = Math.min(...years);
        const maxYear = Math.max(...years);
        setYears([minYear, maxYear]);
        // Asegúrate de establecer el rango inicial de precios al rango completo
        setPriceRange([Math.min(...prices), Math.max(...prices)]);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const applyFilters = () => {
    let result = cars;
    // Aquí, por ejemplo, podrías filtrar coches basándote en la marca seleccionada
    if (selectedBrand) {
      result = result.filter((car) => car.marca === selectedBrand);
    }
    if (selectedModel) {
      result = result.filter((car) => car.modelo === selectedModel);
    }
    if (selectedYear) {
      result = result.filter((car) => car.año === selectedYear);
    }
    if (selectedTransmission) {
      result = result.filter((car) => car.transmision === selectedTransmission);
    }
    if (selectedFuel) {
      result = result.filter((car) => car.combustible === selectedFuel);
    }
    if (priceRange) {
      result = result.filter(
        (car) => priceRange[0] <= car.precio <= priceRange[1]
      );
    }
    if (yearRange) {
      result = result.filter(
        (car) => car.año >= yearRange[0] && car.año <= yearRange[1]
      );
    }
    result = result.filter(
      (car) => car.precio >= priceRange[0] && car.precio <= priceRange[1]
    );
    setFilteredCars(result);
  };

  // Función para limpiar los filtros
  const clearFilters = () => {
    setSelectedBrand(''); // Establece a un valor vacío para seleccionar la opción deshabilitada
    setSelectedModel('');
    setSelectedYear('');
    setSelectedTransmission('');
    setSelectedFuel('');
    setPriceRange([minPrice, maxPrice]);
    setYearRange(years);
    setFilteredCars(cars); // Resetea a todos los coches
  };

  // Función que actualiza el rango de precios tanto desde el Slider como desde los inputs
  const handlePriceChange = (newRange) => {
    if (!isNaN(newRange[0]) && !isNaN(newRange[1])) {
      setPriceRange(newRange.map(Number));
    }
  };

  // Función para manejar el cambio de los campos de entrada, evitando el cero inicial
  const handleInputChange = (index, value) => {
    // Eliminar cualquier caracter no numérico que no sea el punto para mil
    const cleanValue = value.replace(/[^0-9]/g, '');
    const newRange = [...priceRange];
    newRange[index] = cleanValue === '' ? '0' : cleanValue; // Acepta un string vacío como '0'
    handlePriceChange(newRange);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const carsData = await getCars();
        const inStockCars = carsData.filter((car) => car.estado === 'En stock');
        setCars(inStockCars);
        setFilteredCars(inStockCars);
        setBrands([...new Set(inStockCars.map((car) => car.marca))]);
        setTransmissions([
          ...new Set(inStockCars.map((car) => car.transmision)),
        ]);
        setFuels([...new Set(inStockCars.map((car) => car.combustible))]);
        const prices = inStockCars.map((car) => car.precio);
        setMinPrice(Math.min(...prices));
        setMaxPrice(Math.max(...prices));
        const years = inStockCars.map((car) => car.año);
        setYearRange([Math.min(...years), Math.max(...years)]);
        setPriceRange([Math.min(...prices), Math.max(...prices)]);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Agregar useEffect para actualizar modelos basados en la marca seleccionada
  useEffect(() => {
    if (selectedBrand) {
      const filteredModels = cars
        .filter((car) => car.marca === selectedBrand)
        .map((car) => car.modelo);
      setModels([...new Set(filteredModels)]);
    } else {
      setModels([]);
    }
  }, [selectedBrand]); // Dependencia: selectedBrand

  return (
    <div className='buy-page p-6 space-y-6 lg:mx-20'>
      <h1 className='text-3xl font-bold mb-12 text-left'>
        Compra tu proximo auto en CarTrade
      </h1>

      {/* Filtros */}
      <div className='space-y-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          {/* Marca */}
          <select
            className='select select-bordered w-full'
            value={selectedBrand || ''} // <-- Asegúrate de que selectedBrand es una cadena vacía o null por defecto
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option disabled='disabled' value=''>
              Marca
            </option>
            {brands.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>

          {/* Modelo */}
          <select
            className='select select-bordered w-full'
            value={selectedModel || ''}
            onChange={(e) => setSelectedModel(e.target.value)}
          >
            <option disabled='disabled' value=''>
              Modelo
            </option>
            {models.map((model, index) => (
              <option key={index} value={model}>
                {model}
              </option>
            ))}
          </select>

          {/* Transmisión */}
          <select
            className='select select-bordered w-full'
            value={selectedTransmission || ''}
            onChange={(e) => setSelectedTransmission(e.target.value)}
          >
            <option disabled='disabled' value=''>
              Transmisión
            </option>
            {transmissions.map((transmission, index) => (
              <option key={index} value={transmission}>
                {transmission}
              </option>
            ))}
          </select>

          {/* Combustible */}
          <select
            className='select select-bordered w-full'
            value={selectedFuel || ''}
            onChange={(e) => setSelectedFuel(e.target.value)}
          >
            <option disabled='disabled' value=''>
              Combustible
            </option>
            {fuels.map((fuel, index) => (
              <option key={index} value={fuel}>
                {fuel}
              </option>
            ))}
          </select>

          {/* Precio */}
          <div className='lg:col-span-2 flex justify-center mx-2'>
            <div className='w-full lg:w-3/4'>
              <p className='text-center font-semibold'>Rango de Precio</p>
              <div className='mt-1'>
                <Slider
                  range
                  min={minPrice}
                  max={maxPrice}
                  step={500000}
                  value={priceRange.map(Number)} // Asegurarse de que siempre sean números
                  onChange={handlePriceChange} // Usar onChange para actualización instantánea
                />
                <div className='flex justify-between mt-2'>
                  <input
                    type='text' // Usar tipo text para controlar el formato del input
                    value={formatNumber(priceRange[0])} // Convertir el número a string para el input
                    onChange={(e) => handleInputChange(0, e.target.value)}
                    maxLength='11'
                    className='flex-1 border rounded-md p-2 mr-2 min-w-0'
                  />
                  <input
                    type='text' // Usar tipo text para controlar el formato del input
                    value={formatNumber(priceRange[1])} // Convertir el número a string para el input
                    onChange={(e) => handleInputChange(1, e.target.value)}
                    maxLength='11'
                    className='flex-1 border rounded-md p-2 ml-2 min-w-0'
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Año */}
          <div className='lg:col-span-2 flex justify-center mx-2'>
            <div className='w-full lg:w-3/4'>
              <p className='text-center font-semibold'>Rango de Año</p>
              <div className='mt-1'>
                <Slider
                  range
                  min={years[0]}
                  max={years[1]}
                  value={yearRange}
                  onChange={(newRange) => setYearRange(newRange)}
                />

                <div className='flex justify-between mt-2'>
                  <input
                    type='text' // Usar tipo text para controlar el formato del input
                    value={formatNumber(yearRange[0])} // Convertir el número a string para el input
                    onChange={(e) => handleInputChange(0, e.target.value)}
                    maxLength='11'
                    className='flex-1 border rounded-md p-2 mr-2 min-w-0'
                  />
                  <input
                    type='text' // Usar tipo text para controlar el formato del input
                    value={formatNumber(yearRange[1])} // Convertir el número a string para el input
                    onChange={(e) => handleInputChange(1, e.target.value)}
                    maxLength='11'
                    className='flex-1 border rounded-md p-2 ml-2 min-w-0'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={applyFilters}
          className='btn btn-outline btn-wide mr-2'
        >
          Aplicar filtros
        </button>
        <button
          onClick={clearFilters}
          className='btn btn-outline btn-wide mr-2'
        >
          Limpiar filtros
        </button>
      </div>

      <div className='landing-page container mx-auto px-4 sm:px-6 lg:px-8 mt-8'>
        {loading ? (
          <div className='flex flex-col items-center justify-center my-40'>
            <BeatLoader size={30} color='#023e8a' />
            <p className='mt-4'>Cargando autos...</p>
          </div>
        ) : (
          <div className='flex justify-center'>
            <CarList cars={filteredCars} />
          </div>
        )}
      </div>

      <div className='bg-gray-100 p-4 rounded-lg'>
        <h2 className='text-xl font-semibold mb-4'>¿No encontraste tu auto?</h2>
        <p>
          Nosotros te lo buscamos a una baja comisión y con grandes tecnologías
          de revisión.
        </p>
        <Link to='/contacto' className='btn btn-outline mt-4'>
          Contacto
        </Link>
      </div>
    </div>
  );
}

export default Buy;
