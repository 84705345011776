// src/views/Sell.js
import React, { useRef, useState } from "react";
import InicioSell from "../components/InicioSell";
import paso1 from "../utils/paso1.svg";
import paso2 from "../utils/paso2.svg";
import paso3Nuevo from "../utils/paso3Nuevo.svg";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Sell() {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    if (form.current) {
      form.current.reset();
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData.entries());

    axios
      .post("https://cartrade-65f4c7ae0a47.herokuapp.com/enviarMail", data)
      .then((response) => {
        toast.success("Email enviado con éxito");
        setLoading(false);
        resetForm();
      })
      .catch((error) => {
        toast.error("Error al enviar el formulario");
        console.error("Error al enviar el formulario:", error);
        setLoading(false);
      });
  };

  return (
    <div className="sell-page p-6 space-y-6 lg:mx-20">
      <div>
        <InicioSell />
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="text-center space-y-2">
          <img src={paso1} alt="Paso 1" className="mx-auto h-96" />
          <p className="text-3xl">
            1. Contáctanos y detállanos las especificaciones de tu auto.
          </p>
        </div>
        <div className="text-center space-y-2">
          <img src={paso2} alt="Paso 2" className="mx-auto h-96" />
          <p className="text-3xl">
            2. Agenda un horario para revisión, lavado y fotografías 100%
            gratis.
          </p>
        </div>
        <div className="text-center space-y-2 mb-20">
          <img src={paso3Nuevo} alt="Paso 3" className="mx-auto h-96" />
          <p className="text-3xl">
            3. Publicaremos a el precio acordado y te contactaremos para agendar
            próximas visitas.
          </p>
        </div>
      </div>

      <div className="p-4 bg-blue-100 rounded-md shadow-xl mx-auto max-w-2xl">
        <h2 className="text-3xl font-semibold mb-4">Tasa tu auto gratis</h2>
        <form ref={form} className="space-y-4" onSubmit={sendEmail}>
          <input type="hidden" name="tipoFormulario" value="formularioTasa" />
          <input
            className="input input-bordered w-full"
            type="text"
            placeholder="Nombre y apellido"
            name="from_name"
            required
          />
          <input
            className="input input-bordered w-full"
            type="text"
            placeholder="Email"
            name="user_email"
            required
          />
          <input
            className="input input-bordered w-full"
            type="telefono"
            name="telefono"
            placeholder="Numero de telefono"
            required
          />
          <input
            className="input input-bordered w-full"
            type="text"
            name="Modelo"
            placeholder="Modelo del auto"
            required
          />
          <input
            className="input input-bordered w-full"
            type="number"
            placeholder="Año del auto"
            min="1900"
            max="2099"
            pattern="\d{4}"
            title="Por favor introduce un año válido de 4 dígitos."
            name="Year"
            required
          />
          <input
            className="input input-bordered w-full"
            type="patente"
            placeholder="Patente"
            name="Patente"
            required
          />
          <button
            className="btn btn-outline w-full"
            type="submit"
            disabled={loading}
          >
            {loading ? <BeatLoader size={8} color="#FFFFFF" /> : "Enviar"}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Sell;
