import React from "react";
import FotoSell from "../utils/FotoSell.svg";

const InicioSell = () => {
  return (
    <div className="flex flex-wrap mt-10 mb-20">
      {/* Columna del Eslogan */}
      <div className="w-full md:w-1/2 flex items-center justify-center">
        {" "}
        {/* Ajusta el color de fondo según tu preferencia */}
        <div className="text-left p-12">
          <h1 className="text-6xl font-bold mb-6">
            Vendemos tu auto con una baja comisión.
          </h1>
          <h2 className="text-xl mb-16">
            Vender tu auto ahora es más fácil que nunca con CarTrade: solo tres
            pasos simples desde la comodidad de tu hogar y a una baja comisión.
          </h2>
        </div>
      </div>

      {/* Columna de la Imagen */}
      <div className="w-full md:w-1/2 flex justify-center items-center">
        <img
          src={FotoSell} // Usando la imagen importada
          alt="Imagen de Bienvenida"
          className="w-4/5 w-full object-cover" // Ajusta la imagen para que cubra completamente el espacio disponible
        />
      </div>
    </div>
  );
};

export default InicioSell;
