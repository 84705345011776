import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

export async function createCar(carData) {
  try {
    const docRef = await addDoc(collection(db, "cars"), carData);
    console.log("Auto añadido con ID: ", docRef.id);
    return docRef.id; // Puedes retornar el ID del auto creado si lo necesitas posteriormente
  } catch (error) {
    console.error("Error al agregar auto:", error);
    throw error; // Lanza el error para que puedas manejarlo en donde llames a esta función
  }
}

export async function getCars() {
  try {
    const carsCollection = await getDocs(collection(db, "cars"));
    const carsList = carsCollection.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return carsList; // Retorna una lista de autos
  } catch (error) {
    console.error("Error al obtener autos:", error);
    throw error; // Lanza el error para que puedas manejarlo en donde llames a esta función
  }
}

export const getCarById = async (id) => {
  const carRef = doc(db, "cars", id);
  const carSnap = await getDoc(carRef);
  if (carSnap.exists()) {
    return { id: carSnap.id, ...carSnap.data() };
  } else {
    return null;
  }
};

export const getRecentCars = async () => {
  try {
    // Crea una consulta para obtener los tres autos más recientes en stock
    const carsQuery = query(
      collection(db, "cars"),
      where("estado", "==", "En stock"),
      orderBy("createdAt", "desc"),
      limit(3)
    );

    // Ejecuta la consulta
    const querySnapshot = await getDocs(carsQuery);

    // Convierte los documentos de la consulta a objetos y devuelve un array de objetos
    const recentCars = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return recentCars;
  } catch (error) {
    console.error("Error fetching recent cars:", error);
    throw error; // Re-lanza el error para que pueda ser manejado por la función que llamó a getRecentCars
  }
};
