import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/56971356516?text=Hola,%20estoy%20interesado%20en"
      target="_blank"
      rel="noopener noreferrer"
      className="text-green-500 hover:text-green-600"
    >
      <FaWhatsapp size={40} />
    </a>
  );
};

export default WhatsAppButton;
