import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCarById } from "../firebase/cars";
import { formatNumber } from "../utils/Functions";
import {
  FaStar,
  FaCalendarAlt,
  FaRoad,
  FaCog,
  FaGasPump,
  FaInfoCircle,
  FaCar,
  FaCarSide,
  FaUsers,
  FaCircle,
  FaHammer,
  FaTint,
} from "react-icons/fa";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importa otros íconos según tus necesidades

function CarDetails() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const carData = await getCarById(id);
        setCar(carData);
      } catch (error) {
        console.error("Error al obtener los detalles del auto:", error);
      }
    };

    fetchCarDetails();
  }, [id]);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    axios
      .post("https://cartrade-65f4c7ae0a47.herokuapp.com/enviarMail", data)
      .then((response) => {
        toast.success("Email enviado con éxito");
        e.target.reset(); // Resetea el formulario
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error al enviar el formulario");
        console.error("Error al enviar el formulario:", error);
        setLoading(false);
      });
  };

  if (!car) return <BeatLoader />;

  return (
    <div className="car-details p-6 space-y-6 lg:mx-20">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Contenedor del carrusel */}
        <div className="w-full md:w-1/2">
          <div className="carousel w-full rounded-lg h-96">
            {car.fotos.map((foto, index) => (
              <div
                id={`item${index}`}
                key={index}
                className="carousel-item w-full"
              >
                <img
                  src={foto}
                  alt={`Imagen ${index} de ${car.modelo}`}
                  className="w-full object-cover"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-center w-full py-2 gap-2 mt-4">
            {car.fotos.map((_, index) => (
              <a href={`#item${index}`} key={index} className="btn btn-xs">
                {index + 1}
              </a>
            ))}
          </div>
        </div>

        {/* Contenedor de los detalles */}
        <div className="space-y-4">
          <h1 className="text-4xl font-bold mb-4 text-left">
            {car.marca} {car.modelo} {car.version}
          </h1>
          <div className="text-3xl font-semibold text-left text-base-500">
            ${formatNumber(car.precio)}
          </div>
          <div className="flex my-2">
            {/* Aquí irían las estrellas */}
            <FaStar className="text-warning" />
            <FaStar className="text-warning" />
            <FaStar className="text-warning" />
            <FaStar className="text-warning" />
            <FaStar className="text-warning" />
          </div>

          <div className="grid rounded-lg overflow-hidden border">
            <div className="flex items-center justify-between bg-gray-100 p-2">
              <span className="flex">
                {" "}
                <FaRoad className="text-lg my-1 mr-2" />
                Kilometros
              </span>
              <span>{formatNumber(car.kilometros)}</span>
            </div>
            <div className="flex items-center justify-between border-t border-gray-300 p-2">
              <span className="flex">
                <FaCalendarAlt className="text-lg my-1 mr-2" /> Año
              </span>
              <span>{car.año}</span>
            </div>
            <div className="flex items-center justify-between border-t border-gray-300 bg-gray-100 p-2">
              <span className="flex">
                <FaGasPump className="text-lg my-1 mr-2" /> Combustible
              </span>
              <span>{car.combustible}</span>
            </div>
            <div className="flex items-center justify-between border-t border-gray-300 p-2">
              <span className="flex">
                <FaCog className="text-lg my-1 mr-2" /> Transmisión
              </span>
              <span>{car.transmision}</span>
            </div>
          </div>
          <a href="#contact-form" className="btn btn-outline btn-wide">
            Comprar
          </a>
        </div>
      </div>

      {/* Descripción del auto */}
      <div className="my-6 rounded-lg overflow-hidden border bg-white p-4 shadow-xl">
        {/* Título de descripción con ícono */}
        <div className="flex items-center mb-2">
          <FaInfoCircle className="text-lg mr-1" />
          <h3 className="text-2xl font-bold">Descripción</h3>
        </div>
        {/* Texto de descripción */}
        <p className="text-gray-700 text-left">
          {car.descripcion ||
            "No se proporcionó una descripción para este auto."}
        </p>
      </div>

      {/* Detalles adicionales del auto en un grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700 rounded-lg overflow-hidden border bg-white p-4 shadow-xl text-left">
        {/* Fila 1 */}
        <div className="flex justify-between items-center bg-gray-100 rounded p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaCircle className="text mr-2" /> Marca:
          </div>
          <div>{car.marca}</div>
        </div>

        <div className="flex justify-between items-center p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaHammer className="text-lg mr-2" /> Carrocería:
          </div>
          <div>{car.carroceria}</div>
        </div>

        {/* Fila 2 */}
        <div className="flex justify-between items-center p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaCar className="text-lg mr-2" /> Modelo:
          </div>
          <div>{car.modelo}</div>
        </div>

        <div className="flex justify-between items-center bg-gray-100 rounded p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaGasPump className="text mr-2" /> Combustible:
          </div>
          <div>{car.combustible}</div>
        </div>

        {/* Fila 3 */}
        <div className="flex justify-between items-center bg-gray-100 rounded p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaCarSide className="text-lg mr-2" /> Versión:
          </div>
          <div>{car.version}</div>
        </div>

        <div className="flex justify-between items-center p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaTint className="text-lg mr-2" /> Color:
          </div>
          <div>{car.color}</div>
        </div>

        {/* Fila 4 */}
        <div className="flex justify-between items-center p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaCalendarAlt className="text mr-2" /> Año:
          </div>
          <div>{car.año}</div>
        </div>

        <div className="flex justify-between items-center bg-gray-100 rounded p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaCog className="text mr-2" /> Transmisión:
          </div>
          <div>{car.transmision}</div>
        </div>

        {/* Fila 5 */}
        <div className="flex justify-between items-center bg-gray-100 rounded p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaRoad className="text mr-2" /> Kilómetros:
          </div>
          <div>{formatNumber(car.kilometros)}</div>
        </div>

        <div className="flex justify-between items-center p-2">
          <div className="font-semibold flex items-center">
            {" "}
            <FaUsers className="text mr-2" /> Dueños anteriores:
          </div>
          <div>{car.dueños}</div>
        </div>
      </div>

      {/* Sección "Te interesa este auto?" */}
      <div
        id="contact-form"
        className="my-6 p-6 rounded-lg border bg-white shadow-xl"
      >
        <h3 className="text-3xl font-bold mb-4 text-left">
          ¿Te interesa este auto?
        </h3>
        <form onSubmit={sendEmail} className="space-y-4">
          {/* Campos ocultos con detalles del auto */}
          <input type="hidden" name="tipoFormulario" value="formularioVenta" />
          <input type="hidden" name="car_brand" value={car.marca} />
          <input type="hidden" name="car_model" value={car.modelo} />
          <input type="hidden" name="car_version" value={car.version} />
          <input type="hidden" name="car_year" value={car.año} />
          <div className="flex flex-wrap flex-col md:flex-row gap-4">
            <div className="flex-1">
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre completo
              </label>
              <input
                type="text"
                id="fullName"
                name="from_name"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Número de teléfono
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="9 1234 5678"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Mensaje
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ? (
                <BeatLoader size={8} color="#FFFFFF" />
              ) : (
                "Contactanos"
              )}
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default CarDetails;
