// src/views/LandingPage.js
import React, { useEffect, useState } from "react";
import CarList from "../components/carList";
import { getRecentCars } from "../firebase/cars"; // Asumiendo que tienes esta función
import { Link } from "react-router-dom";
import WelcomeSection from "../components/Recibimiento";
import { BeatLoader } from "react-spinners";

function LandingPage() {
  const [recentCars, setRecentCars] = useState([]);
  const [loading, setLoading] = useState(true); // Nuevo estado para manejar la carga

  useEffect(() => {
    const fetchRecentCars = async () => {
      setLoading(true); // Comenzar a cargar
      try {
        const recentCarsData = await getRecentCars();
        console.log(recentCarsData);
        setRecentCars(recentCarsData);
      } catch (error) {
        console.error("Error fetching recent cars:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentCars();
  }, []);

  return (
    <div className="landing-page container mx-auto p-0 m-0">
      <div>
        <WelcomeSection />
      </div>

      {/* Recién Llegados */}
      <div className="mb-6">
        <h2 className="text-5xl font-bold mb-8">Recién Llegados</h2>
        {loading ? (
          <div className="flex flex-col items-center justify-center my-40">
            <BeatLoader size={30} color="#023e8a" />
            <p className="mt-4">Cargando autos recien llegados...</p>
          </div>
        ) : (
          <div className="flex justify-center mx-8">
            <CarList cars={recentCars} />
          </div>
        )}
      </div>

      <Link to="/buy" className="btn bg-principal btn-wide text-white mb-10">
        VER TODOS
      </Link>
      <div className="divider mb-10 mx-10 lg:mx-20"></div>

      {/* Cualidades del Servicio */}
      <h2 className="text-5xl font-bold mb-10">¿Por qué nosotros?</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-4 mx-8 lg:mx-20">
        <div className="feature text-center bg-white p-6 rounded-lg shadow-lg">
          <div className="icon mb-4">
            <i className="fas fa-check text-5xl text-principal"></i>
          </div>
          <h3 className="text-2xl font-bold mb-2">Fácil</h3>
          <p>Encuentra tu auto ideal en minutos</p>
        </div>
        <div className="feature text-center bg-white p-6 rounded-lg shadow-lg">
          <div className="icon mb-4">
            <i className="fas fa-lock text-5xl text-principal"></i>
          </div>
          <h3 className="text-2xl font-bold mb-2">Seguro</h3>
          <p>Transacciones seguras y confiables</p>
        </div>
        <div className="feature text-center bg-white p-6 rounded-lg shadow-lg">
          <div className="icon mb-4">
            <i className="fas fa-home text-5xl text-principal"></i>
          </div>
          <h3 className="text-2xl font-bold mb-2">Personalizado</h3>
          <p>Proceso de venta rápida y a domicilio</p>
        </div>
      </div>

      {/* ¿Por qué nosotros? */}
      <div className="mb-12 feature text-center p-6 rounded-lg shadow-lg mx-8 lg:mx-20">
        <p>
          En CarTrade, ofrecemos una experiencia personalizada y eficiente en la
          compra-venta de autos, adaptándonos a tus necesidades únicas. Nuestro
          equipo experto te guía en cada paso, desde seleccionar el auto ideal
          hasta conseguir el mejor trato para tu vehículo. Con nosotros,
          disfrutas de un proceso sencillo, seguro y adaptado a ti, garantizando
          la mejor elección en tu experiencia automotriz
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
